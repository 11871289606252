@import '@/styles/new-variables';

.notificationsIcon {
  position: relative;
  display: flex;
  padding: 0.375rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid $transparent-white-10;
  transition-property: border-color color;
  transition-duration: 300ms;
  cursor: pointer;

  &:hover {
    border-color: $primary;
    color: $primary;
  }

  .hasUnReadNotifications {
    color: $primary;
  }

  .badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(30%, -30%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    width: toRem(18);
    height: toRem(18);
    border-radius: 50%;
    background: $red;
    font-size: toRem(10);
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 13px */
  }
}

.slideEnter {
  transform: translateX(100%);
}

.slideEnterActive {
  transform: translateX(0);
  transition: transform 0.3s;
}

.slideExit {
  transform: translateX(0);
}

.slideExitActive {
  transform: translateX(100%);
  transition: transform 0.3s;
}
