@import '@/styles/functions';
@import '@/styles/new-variables';

.container {
  position: relative;
  display: block;
  max-width: toRem(409);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 0.333px solid $transparent-white-10;

  .headerLeft {
    display: flex;
    align-items: center;
    gap: toRem(10);

    .title {
      color: $white;
      font-size: toRem(18);
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 23.4px */
    }
  }

  & > .backButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $transparent-white-10;
    backdrop-filter: blur(30px);
    width: toRem(24);
    height: toRem(24);
  }
  .rotate {
    transform: rotate(180deg);
  }
}
